import React from 'react';

// import clock
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
//clock css
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

//import video
import VideoBg from '../src/assets/video.mp4';

//import css
import './styles.css';

const App = () => {
  return (
  <section className='page'>
    {/* overlay */}
    <div className='overlay'></div>
    {/* Video */}
    <video src={VideoBg} autoPlay loop muted></video>
    {/* content */}
    <div className='page_content'>
      <h1>Launching Soon</h1>
      <h3>Leave your email and we'll let you know once the site goes live.</h3>
      {/* Clock */}
      <FlipClockCountdown to={new Date().getTime() + 200 * 3600 * 1000 + 5000} className='flip-clock' labels={['DAYS', 'HOURS', 'MINUTES', 'SECONDS']} duration={0.5}/>
      {/* button */}
      <button className='btn'>Notify me</button>
    </div>
  </section>
  );
};

export default App;
